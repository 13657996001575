@import "../../assets/css/config.scss";

.wp-actions {
  margin-bottom: 1rem;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: $lg) {
    justify-content: center;
  }

  &_edit {
    cursor: pointer;
    color: var(--purple-300);

    &:hover {
      opacity: 0.7;
    }
  }

  &_delete {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &_search {
    width: auto;

    @media screen and (max-width: $lg) {
      width: 100%;
    }

    .wp-search {
      width: 295px;

      @media screen and (max-width: $lg) {
        width: 100%;
        flex: 1;
      }
    }
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-insert {
  margin-top: 10px;
  @media screen and (min-width: $md) {
    gap: 10px;
  }
}

.wp-actions-btn {
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
}

.wp-actions-btn {
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
}

.wp-row-actions {
  display: flex;
  gap: 6px;
  justify-content: flex-end;

  .wp-see-more {
    display: flex !important;
    gap: 6px !important;
    align-items: center !important;
    padding: 4px 12px !important;
  }

  &-edit {
    .ant-btn-icon {
      padding-left: 2px !important;
      padding-top: 4px !important;
    }
  }

  &-delete {
    .ant-btn-icon {
      padding-top: 4px !important;
    }
  }
}
