@import "../../assets/css/config.scss";

.project-title {
  color: var(--orange-700) !important;
}

.table-cwa {
  width: 100%;
  // height: 616px;
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;

  .ant-table-thead {
    tr td:first-child {
      padding: 5px;
      padding-left: 50px;
      text-align: left;
      border-bottom-left-radius: 10px;
      background-color: var(--gray-300);
    }

    tr th:last-child {
      padding: 5px;
      text-align: left;
      border-bottom-right-radius: 10px;
    }

    tr th {
      background-color: var(--gray-300);
    }
  }
}

.cwa-measure-modal {
  padding: 0;
  right: 40px;
  float: right;

  @media screen and (max-width: $sm) {
    right: 0px;
    float: none;
  }

  .ant-modal-content {
    padding: 0px;
    border-radius: 10px 10px;

    .ant-modal-body {
      border-radius: 10px 10px;
      overflow: hidden;
      border-radius: 10px 10px;
    }
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-insert {
  margin-top: 10px;
  @media screen and (min-width: $md) {
    gap: 10px;
  }
}

.ant-table-thead .ant-table-selection-column {
  background: var(--gray-300) !important;
}
