@import "../../assets/css/config.scss";

.wp-delete-modal {
  max-width: 1300px !important;
  width: 100% !important;

  @media screen and (max-width: $lg) {
    .ant-modal,
    .ant-modal-content {
      height: 100vh;
      width: 100vw;
      margin: 0;
      top: 0;
    }
    .ant-modal-body {
      height: calc(100vh - 110px);
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px;

    @media screen and (max-width: $lg) {
      overflow: scroll !important;
      height: 100%;

      .ant-table-wrapper {
        overflow: auto !important;
      }
    }
  }

  .ant-table-thead {
    tr td:first-child {
      padding: 5px;
      padding-left: 50px;
      text-align: left;
      border-bottom-left-radius: 10px;
      background-color: #fafafa !important;
    }

    tr th:last-child {
      padding: 5px;
      text-align: left;
      border-bottom-right-radius: 10px;
    }

    tr th {
      background-color: #fafafa !important;
    }
  }
}

.wp-delete-modal-root {
  max-width: 1300px !important;
  width: 100% !important;

  @media screen and (max-width: $lg) {
    .ant-modal,
    .ant-modal-content {
      height: 100vh;
      width: 100vw;
      margin: 0;
      top: 0;
    }

    .ant-modal-body {
      height: calc(100vh - 110px);
    }

    .ant-table-wrapper {
      overflow: auto !important;
    }
  }
}
