@import "../../assets/css/config.scss";

.searchContainer {
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: flex-end;

  .inputSearch {
    border: #d9d9d9 1px solid;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    width: 100%;

    @media screen and (max-width: $md) {
      flex: 1;
      font-size: 14px;
    }
  }

  .searchButton {
    background-color: transparent;
    border: none;
    width: 35px;
    height: 35px !important;
    outline: none !important;
    cursor: pointer;
    padding: 3px !important;
  }
}
