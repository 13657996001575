.form-create-container-WP {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-height: 600px !important;
  .collapse-list {
    overflow-y: scroll;
  }
}
.container-buttons-WP-add {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 8px;
}
