@import "../../assets/css/config.scss";

.profile-card {
  &_container {
    display: flex;
    gap: 16px;

    @media screen and (max-width: $sm) {
      gap: 0;
      flex-direction: column;
    }

    &_avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;

      @media screen and (max-width: $sm) {
        align-self: center;
      }

      .ant-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 80px;
        font-size: 32px !important;
      }
      &_photo {
        position: relative;
        height: 100%;
        width: 100%;

        &_img {
          border-radius: 50%;
          width: 100%;

          &_hovered {
            background: rgba($color: #000000, $alpha: 0.45);
            border-radius: 50%;
            color: white;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }
      }
    }

    &_input-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;

      &_actions {
        display: flex;
        gap: 8px;
        justify-content: flex-end;
      }
    }
  }
}
