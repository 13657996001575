@import "../../assets/css/config.scss";

.activity-filters {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  flex-wrap: wrap;

  .ant-select-selector,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content,
  .ant-select {
    height: 36px !important;
  }

  .ant-select-selection-placeholder:after,
  .ant-select-selection-item::after {
    padding-top: 2px !important;
  }
}

.activity-search {
  width: auto !important;
  min-width: 300px;
}
