@import "../../assets/css/config.scss";

.comment-list {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .comment {
    width: 80%;

    &_message {
      display: flex;
      padding: 4px 8px;
      font-size: 13px !important;
      line-height: 1.1rem;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      width: 100%;
    }

    &_data {
      color: #aaa;
      font-size: 12px;
      display: flex;
      gap: 4px;
      margin-top: 2px;
    }
  }
}

.comment-submit {
  @media screen and (max-width: $sm) {
    width: 188px;
  }
}
