.container-select-validation {
  border-bottom: 1px solid #eaeaea;
  padding: 20px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.container-select-validation:hover {
  background-color: #eaeaea;
}
.container-text-validation {
  display: flex;
  flex-direction: column;
}
.contrainer-selects {
  overflow-y: scroll;
  max-height: 400px;
}
.title-validation {
  font-size: 14px;
  font-weight: bold;
}
.description-validation {
  font-size: 12px;
}
