



// Container para exibir a diferença
.container-difference {
  display: flex;
  gap: 10px; 
  overflow: hidden;

  .table-wrapper {
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    max-width: 100%;
  }

  .table-wrapper::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--purple-200);
    border-radius: 5px;
  }

  .percent-change-cell {
    display: flex;
    align-items: center;
  }

  .arrow-icon {
    margin: 0 5px;
  }
  
  .green-arrow {
    color: #69da7c;
  }

  .gray-arrow {
    color: var(--gray-400);
  }

  .check-icon {
    color: var(--purple-300);
    margin-left: 8px;
  }

  .question-icon {
    color: var(--orange-400);
    margin-left: 8px;
  }

  .disabled-row {
    opacity: 0.5;
    background-color: #f5f5f5; 
    pointer-events: none; 
  }
}





.update-bank-container {
  text-align: center;
  padding: 20px;
  max-width: 1200 px;
  margin: 0 auto;

  h3 {
    font-size: 1.8rem;
    color: var(--black-0);
    margin-bottom: 10px;
  }

  .update-text {
    font-size: 1rem;
    color: var(--gray-400);
    margin-bottom: 15px;
  }


  
  .selected-items-list {
    margin-top: 20px;
    padding: 15px;
    background-color: var(--purple-100);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  
    .selected-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid var(--gray-300);

      .item-content {
        display: flex;
        align-items: center;
        gap: 26px; /* Espaço entre elementos */
        width: 100%;
        
        .text-label {
          margin-right: 1px; /* Espaço entre o label e o valor */
          font-weight: bold;
          font-size: 15px;
        }
        .text-label-row{
          margin-right: 15px;
        }
      }
    }
  }

  .update-button {
    margin-top: 20px;  
  }



}

.upload-file-container {
  text-align: center;
  padding: 20px;

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    .validation-image {
      width: 300px;
      cursor: pointer;
      border: 1px solid var(--gray-300);
      border-radius: 8px;
      margin-bottom: 10px;

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espaço entre os botões */
    margin-top: 10px;

    .download-button {
      margin-top: 0;
    }
  }
}

.file-upload-modal {
  width: 1400px !important;
  max-width: 90%;

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-close svg {
    margin-right: 15px;
    margin-top: 10px;
  }

  .ant-modal-header {
    background-color: var(--purple-100);
    border-bottom: 1px solid var(--gray-300);
    border-radius: 8px 8px 0 0;
    padding: 16px 24px;
  }

  .ant-modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--black-0);
  }

}

.selected-items-counter {
  margin-bottom: 10px;
  text-align: left;
  font-weight: bold;
  font-size: 16px;  
}

