@import url("./colors.scss");

.ant-card {
  border: none;
  border-radius: 5px !important;
}

.ant-card-head {
  border-bottom: 1px solid #ededed !important;
  color: var(--purple-200) !important;
}

.ant-btn-primary {
  border: 1px solid var(--purple-300) !important;
  background-color: var(--purple-300) !important;
  &:hover {
    background-color: var(--purple-200) !important;
  }
}

.ant-btn-primary[disabled] {
  background-color: #f5f5f5 !important;
  border: 1px solid #d9d9d9 !important;
}

.ant-btn-circle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-pagination-item-active {
  border-color: var(--purple-300) !important;
  a {
    color: var(--purple-300) !important;
  }
}

.ant-form-item {
  width: 100% !important;
  margin-bottom: 0px !important;

  &-label {
    padding-bottom: 2px !important;
  }
}
.ant-spin-dot-item {
  background-color: var(--purple-300) !important;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper,
.ant-select-selector {
  border-radius: 4px !important;
}

.ant-table-row-expand-icon {
  height: 24px !important;
  width: 24px !important;

  &::before {
    top: 11px !important;
  }
  &::after {
    top: 4px !important;
    inset-inline-start: 11px !important;
  }
}

.ant-table {
  .ant-checkbox-inner {
    height: 20px !important;
    width: 20px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    left: 25% !important;
  }
}
