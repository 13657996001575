@import url("./colors.scss");

body {
  margin: 0px;
  padding: 0px;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-6 {
  margin-top: 3rem;
}

.mt-7 {
  margin-top: 3.5rem;
}

.mt-8 {
  margin-top: 4rem;
}

.mt-9 {
  margin-top: 4.5rem;
}

.mt-10 {
  margin-top: 5rem;
}

.mb-05 {
  margin-bottom: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-7 {
  margin-bottom: 3.5rem;
}

.mb-8 {
  margin-bottom: 4rem;
}

.mb-9 {
  margin-bottom: 4.5rem;
}

.mb-10 {
  margin-bottom: 5rem;
}
.mb-05 {
  margin-bottom: 0.25rem;
}

.mbn-1 {
  margin-bottom: -0.5rem;
}

.mbn-2 {
  margin-bottom: -1rem;
}

.mbn-3 {
  margin-bottom: -1.5rem;
}

.mbn-4 {
  margin-bottom: -2rem;
}

.mbn-5 {
  margin-bottom: -2.5rem;
}

.mbn-6 {
  margin-bottom: -3rem;
}

.mbn-7 {
  margin-bottom: -3.5rem;
}

.mbn-8 {
  margin-bottom: -4rem;
}

.mbn-9 {
  margin-bottom: -4.5rem;
}

.mbn-10 {
  margin-bottom: -5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.mr-4 {
  margin-right: 2rem;
}

.mr-5 {
  margin-right: 2.5rem;
}

.mr-6 {
  margin-right: 3rem;
}

.mr-7 {
  margin-right: 3.5rem;
}

.mr-8 {
  margin-right: 4rem;
}

.mr-9 {
  margin-right: 4.5rem;
}

.mr-10 {
  margin-right: 5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-4 {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 2.5rem;
}

.ml-6 {
  margin-left: 3rem;
}

.ml-7 {
  margin-left: 3.5rem;
}

.ml-8 {
  margin-left: 4rem;
}

.ml-9 {
  margin-left: 4.5rem;
}

.ml-10 {
  margin-left: 5rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pt-5 {
  padding-top: 2.5rem;
}

.pt-6 {
  padding-top: 3rem;
}

.pt-7 {
  padding-top: 3.5rem;
}

.pt-8 {
  padding-top: 4rem;
}

.pt-9 {
  padding-top: 4.5rem;
}

.pt-10 {
  padding-top: 5rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}

.pb-6 {
  padding-bottom: 3rem;
}

.pb-7 {
  padding-bottom: 3.5rem;
}

.pb-8 {
  padding-bottom: 4rem;
}

.pb-9 {
  padding-bottom: 4.5rem;
}

.pb-10 {
  padding-bottom: 5rem;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.pr-4 {
  padding-right: 2rem;
}

.pr-5 {
  padding-right: 2.5rem;
}

.pr-6 {
  padding-right: 3rem;
}

.pr-7 {
  padding-right: 3.5rem;
}

.pr-8 {
  padding-right: 4rem;
}

.pr-9 {
  padding-right: 4.5rem;
}

.pr-10 {
  padding-right: 5rem;
}

.pl-1 {
  padding-left: 0.5rem;
}

.pl-2 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.pl-4 {
  padding-left: 2rem;
}

.pl-5 {
  padding-left: 2.5rem;
}

.pl-6 {
  padding-left: 3rem;
}

.pl-7 {
  padding-left: 3.5rem;
}

.pl-8 {
  padding-left: 4rem;
}

.pl-9 {
  padding-left: 4.5rem;
}

.pl-10 {
  padding-left: 5rem;
}

.border {
  border: 1px solid red;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.w-100-import {
  width: 100% !important;
}

.h-100 {
  height: 100%;
}

.h-100-import {
  height: 100% !important;
}

.page-row {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-card-body {
  padding: 20px !important;
}

.ant-card-head {
  padding-left: 20px !important;
}

.button-secondary {
  background-color: #fff !important;
  color: var(--purple-300) !important;
}

.total-card {
  text-align: center;
  color: var(--purple-300) !important;
  font-weight: 300 !important;
  font-size: 46px !important;
  margin: 0 !important;
}

.no-data-text {
  color: #aaaaaa !important;
  display: flex;
  justify-content: center;
  width: 100%;
}

.breadcrumb-loading {
  height: 14px;
  padding-right: 8px !important;

  i {
    background-color: #fff !important;
  }
}

.gray-btn {
  .ant-btn-icon {
    svg {
      fill: rgba($color: #000000, $alpha: 0.6) !important;
    }
  }
}


.fs-8{
  font-size: 8px;
}
.fs-10{
  font-size: 10px;
}
.fs-12{
  font-size: 12px;
}
.fs-14{
  font-size: 14px;
}
.fs-16{
  font-size: 16px;
}
.fs-18{
  font-size: 18px;
}
.fs-20{
  font-size: 20px;
}
.fs-22{
  font-size: 22px;
}

.text-white{
  color: #ffffff;
}

.text-gray{
  color: #aaaaaa;
}

.positive-text-color {
  color: green !important;
}

.negative-text-color {
  color: #bb0000 !important;
}