.project-title {
  color: "var(--orange-700)" !important;
}

.fluit-tabs .ant-tabs-tab-active {
  background: var(--purple-100) !important;
}

.wp-view-data {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

// .fluit-tabs .ant-tabs-nav,
// .fluit-tabs .ant-tabs-nav .ant-tabs-tab-active{
//     border-bottom   :1px solid  #F5B091 !important
// }

// .fluit-tabs .ant-tabs-tab {
//     border:1px solid  #F5B091 !important
// }
