@import "../../assets/css/config.scss";

.dashboard-menu {
  border: none;
  padding: 36px 6px;
  border-radius: 6px;
  color: var(--purple-300);
  width: 160px;

  @media screen and (min-width: $sm) {
    width: 200px;
  }

  span {
    color: var(--purple-300);
  }

  &:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: var(--purple-300);
    span {
      color: #ffffff;
    }
  }
}
