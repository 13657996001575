@import "../../assets/css/config.scss";

.container-WP-modal {
  display: flex;
  justify-content: space-around;
  overflow-y: scroll;
  max-height: 600px;

  @media (max-width: 575px) {
    flex-direction: column;
    padding: 12px;
  }

  @media (max-width: $md) {
    max-height: 100%;
    padding: 0;
    overflow-y: auto;
  }
}
.container-WP-modal::-webkit-scrollbar {
  background-color: transparent;
}

.wp-add-edit-modal {
  @media (max-width: $md) {
    .ant-modal {
      top: 0 !important;
      width: 100vw !important;
      max-width: 100%;
      padding: 0;

      &-content {
        border-radius: 0 !important;
      }
    }
  }
}

.hide-wp-modal {
  opacity: 0 !important;
}
