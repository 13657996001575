:root {
  --orange-100: #f5b09145;
  --orange-300: #f5b091;
  --orange-400: #da4f37;
  --orange-700: #da4f37;

  --purple-100: #e4bbff;
  --purple-200: #bf87e9;
  --purple-300: #a66fde;
  --purple-400: #8736d6;
  --purple-500: #8736d6;
  --purple-600: #8736d6;
  --purple-700: #8736d6;
  --purple-800: #8736d6;
  --purple-900: #8736d6;
  --purple-1000: #8736d6;

  --black-0: #000000;
  --gray-300: #c9c9c9b2;
  --gray-400: #acacac;
}
