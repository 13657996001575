.container-total-BM {
  display: flex;
  gap: 5px;
  margin-bottom: 14px;
  margin-left: 5px;
  span {
    font-size: 16px;
    font-weight: bold;
  }
}

.container-totals-bulletin {
  display: flex;
  flex-direction: column;
}
.totals-BM {
  display: flex;
  gap: 5px;
  font-weight: bold;
  &-reproved,
  &-approved {
    @extend .totals-BM;
  }
  &-reproved {
    color: red;
  }
  &-approved {
    color: rgb(123, 197, 4);
  }
}
