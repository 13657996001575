.fieldsetValidation {
  display: flex;
  border-color: #ffffff;
  font-size: 9px;
}

.fieldsetValidation span {
  font-size: 10px;
  // padding: 2px;
  // line-height: 6px;
  // margin: 0px;
}

.flex-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;

  div {
    margin-left: 4px;
    margin-right: -4px;
    margin-bottom: 4px;

    span {
      display: flex;
      padding-top: 2px;
      align-items: center;
      justify-content: center;
    }
  }
}
