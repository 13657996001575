.contentError {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logoErrorScreen {
  padding-bottom: 50px;
  margin-bottom: 10px;
  width: 200px;
}
.containerErrorPage {
  padding-top: 50px;
}
