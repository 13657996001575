@import "../../assets/css/config.scss";

.project-title {
  color: var(--orange-700) !important;
}

.table-default-style {
  width: 100%;
  // height: 616px;
  border: 1px solid var(--gray-300);
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;

  .ant-table-thead {
    tr td:first-child {
      padding: 5px;
      padding-left: 50px;
      text-align: left;
      border-bottom-left-radius: 0 !important;
      background-color: #fafafa !important;
    }

    tr th:last-child {
      padding: 5px;
      text-align: left;
      border-bottom-right-radius: 0 !important;
    }
    tr th {
      background-color: #fafafa !important;
    }
  }

  &_activity-row {
    cursor: pointer;
    &:hover {
      color: var(--purple-200) !important;
    }
  }
}

.cwa-search {
  width: 250px;

  @media screen and (max-width: $md) {
    width: 100%;
  }
}

tr:has(.table-td-navigate) {
  &:hover {
    color: var(--purple-200) !important;
    cursor: pointer;
  }
}

.table-row-activity {
  td:nth-child(2) {
    width: 70% !important;
  }
}

.measure-modal {
  right: 40px;
  float: right;

  @media screen and (max-width: $sm) {
    right: 0px;
  }

  .ant-modal-content {
    padding: 0px;
    border-radius: 10px 10px;

    .ant-modal-body {
      border-radius: 10px 10px;
      overflow: hidden;
      border: 1px solid var(--orange-400);
      border-radius: 10px 10px;

      .measure-modal-header {
        background-color: var(--orange-300);
        font-weight: bold;
        padding: 3px 20px;
      }

      .measure-modal-sub-header {
        background-color: var(--orange-100);
        padding: 5px 0px;

        .modal-name {
          padding: 0px 20px;
        }

        .modal-description {
          padding: 0px 50px;
        }
      }

      .modal-body {
        padding: 0px 15px;
        text-overflow: ellipsis;
        overflow: hidden;

        .upload-cloud-btn {
          .ant-upload-select {
            height: 30px;
          }

          button {
            @media screen and (max-width: $sm) {
              width: 100px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }

        .uploaded-file-list {
          .ant-upload-list-item {
            margin-top: 0px;
          }

          .ant-upload-list {
            min-height: 90px;
          }
        }

        .modal-upload-button span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          @media screen and (max-width: $sm) {
            width: 80px;
          }
        }
      }
    }
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-insert {
  margin-top: 10px;
  @media screen and (min-width: $md) {
    gap: 10px;
  }
}

.ant-table-thead .ant-table-selection-column {
  background: var(--gray-300) !important;
}
