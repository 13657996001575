.formOne {
    margin: 10px;
}

.form-content {
    background-color: #fafafa;
    padding: 15px;
    border-radius: 10px;
    max-height: 400px; 
    overflow-y: auto; 
}
