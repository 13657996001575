.drawer {
  .ant-drawer-body {
    padding: 0 !important;
  }

  &-menu {
    color: var(--purple-300);
    display: flex;
    gap: 12px;
    padding: 10px 16px;

    &:first-of-type {
      padding-top: 12px !important;
    }

    span {
      font-size: 15px !important;
    }

    &:hover {
      cursor: pointer;
      color: #ffffff;
      background-color: var(--purple-300);
      span {
        color: #ffffff;
      }
    }
  }
}
