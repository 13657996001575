.total-bms-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.info-bm-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 340px;
  justify-content: flex-end;
}
.selects-container-bm {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-wrap: wrap;
}
