@import "../../assets/css/config.scss";

body {
  background: #eee;
}

.login-background {
  background: url("../../assets/images/background-login.png") no-repeat #fff;
  background-size: 1340px;
  background-position: top left;
}

.loginBox {
  text-align: center;
  background-color: #fff;
  padding: 40px 60px 50px 60px;
  border: 1px solid #eee;
  border-radius: 5px;

  .ant-form-item-explain-error {
    text-align: left !important;
  }

  @media screen and (max-width: $sm) {
    padding: 40px 40px 50px 40px;
  }
}

.loginBox .logo {
  width: 150px;
}

.loginBox .ant-input {
  height: 40px;
  width: 250px;
}

.loginBox .submit {
  background-color: var(--purple-300) !important;
  color: white !important;
  border: none !important;
  width: 150px;
  height: auto;

  &:hover {
    background-color: var(--purple-200) !important;
  }
}
.forgotPassword {
  border-bottom: 2px solid var(--purple-1000);
}
// .loginBox .submit:hover,
// .loginBox .submit:active {
//   text-decoration: none;
//   color: var(--black-0);
//   font-weight: 600;
//   border: 1px solid var(--black-0);
// }

.rowLogin {
  height: 100vh;
}
.forgotPassword {
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px solid var(--purple-500);
}

.input-help {
  display: flex;
  justify-content: center !important;
  .ant-form-item-explain-error {
    max-width: 250px !important;
  }
}
