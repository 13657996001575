@import "../../assets/css/config.scss";
.see-employees {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.hired {
  &_data {
    margin-bottom: 20px !important;

    &_content {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      width: 100%;

      &_row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 8px;
        width: 100%;
      }
    }
  }

  &_activities {
    margin-bottom: 20px !important;

    &_content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &_list {
        display: flex;
        justify-content: space-between;
        gap: 12px;
      }

      &_input-container {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 8px;

        @media screen and (min-width: $sm) {
          flex-direction: row;
        }
      }
    }
  }
}

.activities-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 4px;
}
