.form-view-container-WP {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.wp-view {
  &-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
  }

  &-upload {
    max-height: 220px !important;
  }

  &-added {
    color: green !important;
  }

  &-removed {
    color: #bb0000 !important;
  }
}

.create-file-green-border {
  .ant-upload-list-item-create {
    border: 1px solid mediumseagreen !important;
  }
  .ant-tooltip {
    display: none !important;
  }
}
