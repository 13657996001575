.collapse-card {
  &-collapsed {
    .ant-card-head {
      border-bottom: none !important;
    }
  }

  &-hide {
    .ant-card-body {
      opacity: 0 !important;
      padding: 0 !important;
    }
  }
}
