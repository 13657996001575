@import "../../assets/css/config.scss";

.form-container-WP {
  display: flex;
  width: 100%;
  justify-content: space-around;

  @media (max-width: $md) {
    flex-direction: column;
    justify-content: space-around;
    height: fit-content;
  }
}
.upload-container {
  max-height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--purple-300);
    width: 5px;
    border-radius: 11px;
  }
}
.container-column-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}
.activities-WP-container {
  display: flex;
  max-height: 250px;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 12px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--purple-300);
    width: 5px;
    border-radius: 11px;
  }
}
.checkbox-wp {
  margin: 5px 0 0 0 !important;
}
.button-create-container {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}

.wp-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
}

.no-preview {
  .anticon-eye {
    display: none !important;
  }
}
