@import "../../assets/css/config.scss";

.file-list-item {
  border: 1px solid #ddd;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 4px;
  height: 128px !important;
  display: flex !important;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--purple-200);
  }

  @media screen and (max-width: $sm) {
    height: 110px !important;
  }

  &-disabled {
    color: #757575 !important;
  }

  &-delete-icon {
    position: absolute;
    z-index: 1;
    font-size: 24px;
    color: white;
    right: -4px;
    top: -10px;
    background-color: red;
    border-radius: 50%;

    @media screen and (max-width: $sm) {
      font-size: 32px;
    }

    &:hover {
      background-color: rgb(202, 0, 0) !important;
    }

    &-disabled {
      background-color: #adadad !important;
    }
  }

  &-file {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .file-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-disabled {
        opacity: 0.2;
      }

      &-icon {
        color: #9193a7;
        width: 50%;
        height: 80px;

        svg {
          width: 100%;
          height: 50%;
        }
      }

      .pdf-icon {
        color: #e53935 !important;
      }
    }

    &-loading {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-text {
    cursor: pointer;
    font-size: 12px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    flex: 1;
  }
}
