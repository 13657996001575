.containerErro {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errorMessage {
  font-size: 18px;
  text-wrap: wrap;
  text-align: center;
}
.containerErrorImage {
  display: flex;
}
.imageError {
  margin-bottom: 60px;
}
@media (max-width: 490px) {
  .imageError {
    width: 80%;
    margin-inline: auto;
  }
}
