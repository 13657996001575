.selects-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
  text-align: left;
}
.total-container {
  margin-top: 10px;
  display: flex;

  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  span {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }
}
.total-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
}
.container-report-select {
  display: flex;
  flex-wrap: wrap;

  gap: 10px;
}
.date-container {
  width: 250px;
}
.table-report-container {
  overflow-y: scroll;
  overflow-x: scroll;
  width: 100%;
}

.button-export {
  display: flex;
  align-items: center;
  gap: 10px;

  font-weight: bold;
  margin-top: 23px;
}
/* Estilo para o scrollbar */
.table-report-container::-webkit-scrollbar {
  width: 5px; /* Largura do scrollbar */
  height: 7px;
  background-color: transparent;
}

.table-report-container::-webkit-scrollbar-thumb {
  background-color: var(--purple-200);
}

/* Zebra para linhas ímpares */
.scoped-table-report .zebra-stripe {
  background-color: #f5f5f5 !important; /* Cor para linhas ímpares */
}

/* Zebra para colunas fixas (esquerda e direita) */
.scoped-table-report .ant-table-fixed-left .zebra-stripe,
.scoped-table-report .ant-table-fixed-right .zebra-stripe {
  background-color: #f5f5f5 !important; /* Cor para linhas ímpares fixas */
}

.container-buttons-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
}
.container-buttons-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.container-report-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.container-total-report {
  display: flex;
  height: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
