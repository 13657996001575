.actions-container {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  height: 43px;
}
.error_iput {
  color: red;
}
.dynamic-field-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.container-button-footer {
  display: flex;
  width: 100%;
  justify-content: end;
}
