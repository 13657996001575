@import "../../assets/css/config.scss";

.project-title {
  color: var(--orange-700) !important;
}
.container-justifications {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.activity_creation-body {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  border: 1px solid var(--purple-100);
  border-radius: 10px;

  .activity_creation-header {
    padding: 8px;
    font-weight: 600;
  }

  .ant-table-thead {
    tr td:first-child {
      padding: 5px;
      padding-left: 50px;
      text-align: left;
      border-bottom-left-radius: 10px;
    }

    tr th:last-child {
      padding: 5px;
      text-align: left;
    }
  }

  tbody {
    tr {
      background-color: var(--purple-100) !important;
      filter: brightness(1.35) !important;
    }

    .ant-table-row-level-0 {
      filter: brightness(1) !important;
    }
    .ant-table-row-level-1 {
      filter: brightness(1.05) !important;
    }
    .ant-table-row-level-2 {
      filter: brightness(1.1) !important;
    }
    .ant-table-row-level-3 {
      filter: brightness(1.15) !important;
    }
    .ant-table-row-level-4 {
      filter: brightness(1.2) !important;
    }
    .ant-table-row-level-5 {
      filter: brightness(1.25) !important;
    }
    .ant-table-row-level-6 {
      filter: brightness(1.3) !important;
    }

    .ant-table-cell:hover {
      background-color: var(--purple-100) !important;
      filter: brightness(0.9) !important;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    border-block-start-color: #d9d9d9 !important;
  }
}

.activity_creation-contract {
  margin-bottom: 20px !important;

  &_message {
    padding-bottom: 12px;
  }

  &_assign {
    display: flex;
  }

  &_actions {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
}

.activity_creation-files {
  height: 100% !important;

  &-item {
    border: 1px solid #ddd;
    margin-bottom: 12px;
    padding: 8px;
    border-radius: 4px;
    height: 128px !important;
    display: flex !important;
    gap: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--purple-200);
    }

    @media screen and (max-width: $sm) {
      height: 110px !important;
    }

    &-file {
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .file-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-icon {
          color: #9193a7;
          width: 50%;
          height: 80px;

          svg {
            width: 100%;
            height: 50%;
          }
        }

        .pdf-icon {
          color: #e53935 !important;
        }
      }
    }

    &-text {
      cursor: pointer;
      font-size: 12px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      flex: 1;
    }
  }
}

.activity_creation-comment-list {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .comment {
    width: 80%;

    &_message {
      display: flex;
      padding: 4px 8px;
      font-size: 13px !important;
      line-height: 1.1rem;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      width: 100%;
    }

    &_data {
      color: #aaa;
      font-size: 12px;
      display: flex;
      gap: 4px;
      margin-top: 2px;
    }
  }
}

.activity_creation-profile-card {
  display: flex;
  gap: 20px;

  &_avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;

    .ant-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      width: 46px;
      font-size: 18px !important;
    }
  }

  &_input-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.date-label {
  color: rgba(0, 0, 0, 0.45);
}

.date-input {
  padding: 2px 11px;
  border: 1px solid #d9d9d9;
}

.activity_creation-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: $sm) {
    flex-direction: column;

    .upload-cloud-btn {
      width: 100% !important;

      div {
        width: 100% !important;
      }
    }

    button {
      width: 100% !important;
    }
  }

  .activity_creation-submit {
    @media screen and (max-width: $sm) {
      width: 188px;
    }
  }
}

.activity_creation-card .ant-card-body {
  padding: 0px !important;
  .backlog {
    border-left: 1px solid #2e2e2e;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-insert {
  margin-top: 10px;
  @media screen and (min-width: $md) {
    gap: 10px;
  }
}

.activity-upload-button-container {
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.container-info-activity {
  padding-top: 16px !important;
}
.container-cols-activity {
  display: flex;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
  }
}
.col-infodata {
  width: 100%;
}
.container-edit-percentage {
  display: flex;
}
.icon-edit {
  width: 24px;
  margin-left: 12px;
  cursor: pointer;
}
.infoCol {
  height: 25px;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 5px;
}
.container-actions-activity {
  display: flex;
  justify-content: space-between;
}
