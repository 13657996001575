.filter-quality-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.filter-quality-drops {
  display: flex;
  align-items: center;
  gap: 12px;
}
