@import "../../assets/css/config.scss";

.project-title {
  color: var(--orange-700) !important;
}

.activity_creation-body {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  border: 1px solid var(--purple-100);
  border-radius: 10px;

  .activity_creation-header {
    padding: 8px;
    font-weight: 600;
  }

  .ant-table-thead {
    tr td:first-child {
      padding: 5px;
      padding-left: 50px;
      text-align: left;
      border-bottom-left-radius: 10px;
    }

    tr th:last-child {
      padding: 5px;
      text-align: left;
    }
  }

  tbody {
    tr {
      background-color: var(--purple-100) !important;
      filter: brightness(1.35) !important;
    }

    .ant-table-row-level-0 {
      filter: brightness(1) !important;
    }
    .ant-table-row-level-1 {
      filter: brightness(1.05) !important;
    }
    .ant-table-row-level-2 {
      filter: brightness(1.1) !important;
    }
    .ant-table-row-level-3 {
      filter: brightness(1.15) !important;
    }
    .ant-table-row-level-4 {
      filter: brightness(1.2) !important;
    }
    .ant-table-row-level-5 {
      filter: brightness(1.25) !important;
    }
    .ant-table-row-level-6 {
      filter: brightness(1.3) !important;
    }

    .ant-table-cell:hover {
      background-color: var(--purple-100) !important;
      filter: brightness(0.9) !important;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    border-block-start-color: #d9d9d9 !important;
  }

  .activity_creation-history-list {
    width: 100%;
    padding-left: 20px;
  }
}

.date-label {
  color: rgba(0, 0, 0, 0.45);
}

.date-input {
  padding: 2px 11px;
  border: 1px solid #d9d9d9;
}

.activity_creation-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: $sm) {
    flex-direction: column;
  }

  .activity_creation-submit {
    @media screen and (max-width: $sm) {
      width: 188px;
    }
  }
}

.activity_creation-card .ant-card-body {
  padding: 0px !important;
  .backlog {
    border-left: 1px solid #2e2e2e;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-insert {
  margin-top: 10px;
  @media screen and (min-width: $md) {
    gap: 10px;
  }
}
.container-newHired-button {
  margin-left: 16.5%;
}
.table-pagination {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 16%;
}
.container-pagination {
  display: flex;
  justify-content: center;
}
