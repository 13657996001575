.appLinkContainer {
  padding: 24px;
  cursor: pointer;
}
.appLink {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 16px;
}

.underlineLink {
  width: 0%;
  height: 2px;
  background-color: var(--purple-1000);
}

.appLinkContainer:hover .underlineLink {
  color: black !important;
  width: 100%;
  transition: 500ms;
}
