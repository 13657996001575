@import "../../assets/css/config.scss";

.file-table-list-actions {
  display: flex;
  gap: 10px;

  svg {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &-disabled {
    color: var(--gray-400) !important;
    cursor: not-allowed !important;
  }
}

.file-table-list-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
}

.even-row {
  background-color: #f6f6f6;
}

.odd-row {
  background-color: #ffffff;
}