@import "../../../assets/css/config.scss";

.background-rectangle {
  z-index: -1;
  height: 340px;
  width: 100%;
  background-color: var(--purple-300);
  position: absolute;

  @media screen and (min-width: $md) {
    height: 240px;
  }
}

.layout-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: white;
  padding-bottom: 24px;
  flex-direction: column;

  @media screen and (min-width: $md) {
    gap: 8px;
    flex-direction: row;
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    @media screen and (min-width: $md) {
      align-items: flex-start;
    }

    &_title {
      font-size: 20px;
    }

    &_breadcrumb {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      text-align: center;
      padding: 0 12px;

      @media screen and (min-width: $md) {
        padding: 0;
        text-align: left;
      }
    }
  }

  &_actions {
    display: flex;
    gap: 8px;
  }
}

.layout-container {
  margin: 0px auto;
  flex: 1 1 0;
  width: 100%;
  padding: 0px 50px;
  @media screen and (max-width: $md) {
    padding: 0px 5px;
  }
  overflow: auto;
}

.layout-container.show {
  display: block;
}
.layout-container.hide {
  @media screen and (max-width: $md) {
    display: none;
  }
}

.project-title-container {
  justify-content: flex-end;
  @media screen and (max-width: $md) {
    justify-content: center !important;
  }

  .project-title {
    padding-right: 50px;

    @media screen and (max-width: $md) {
      padding: 0;
    }
  }
}

.back-button {
  border: none !important;
}
