@import "../../assets/css/config.scss";

.project-title {
  color: var(--orange-700) !important;
}

.measure_creation-body {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  border: 1px solid var(--purple-100);
  border-radius: 10px;

  .measure_creation-header {
    padding: 8px;
    font-weight: 600;
  }

  .ant-table-thead {
    tr td:first-child {
      padding: 5px;
      padding-left: 50px;
      text-align: left;
      border-bottom-left-radius: 10px;
    }

    tr th:last-child {
      padding: 5px;
      text-align: left;
    }
  }

  tbody {
    tr {
      background-color: var(--purple-100) !important;
      filter: brightness(1.35) !important;
    }

    .ant-table-row-level-0 {
      filter: brightness(1) !important;
    }
    .ant-table-row-level-1 {
      filter: brightness(1.05) !important;
    }
    .ant-table-row-level-2 {
      filter: brightness(1.1) !important;
    }
    .ant-table-row-level-3 {
      filter: brightness(1.15) !important;
    }
    .ant-table-row-level-4 {
      filter: brightness(1.2) !important;
    }
    .ant-table-row-level-5 {
      filter: brightness(1.25) !important;
    }
    .ant-table-row-level-6 {
      filter: brightness(1.3) !important;
    }

    .ant-table-cell:hover {
      background-color: var(--purple-100) !important;
      filter: brightness(0.9) !important;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    border-block-start-color: #d9d9d9 !important;
  }

  .measure_creation-actions {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    width: 100%;

    @media screen and (max-width: $sm) {
      flex-direction: column;
    }

    .measure_creation-submit {
      @media screen and (max-width: $sm) {
        width: 188px;
      }
    }
  }

  .measure_creation-history-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
  }
}

.measure_creation-card .ant-card-body {
  padding: 0px !important;
  .backlog {
    border-left: 1px solid #2e2e2e;
  }
}

.measure-steps {
  .ant-steps-item-active:not(.ant-steps-item-error),
  .ant-steps-item-finish {
    .ant-steps-icon {
      color: var(--purple-200) !important;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-title::after {
      background-color: var(--purple-200) !important;
    }
  }

  .ant-steps-item-icon {
    padding-top: 3px !important;
  }
}

.aprove-measure {
  background-color: #7bbc76 !important;
  color: black !important;

  &:hover {
    background-color: #7bbc76 !important;
    filter: brightness(0.9);
  }
}

.refuse-measure {
  background-color: #c71e1e !important;
  color: white !important;

  &:hover {
    background-color: #c71e1e !important;
    filter: brightness(0.9);
  }
}

.measure-contract-value {
  margin: 0 !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-insert {
  margin-top: 10px;
  @media screen and (min-width: $md) {
    gap: 10px;
  }
}

.container-cards-measure {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 12px;

  .card-cost {
    width: 100%;
  }
}
.container-values {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: 100%;

  span {
    font-size: 16px;
    font-weight: bold;
  }
}
