@import "../../assets/css/config.scss";

.wp-create-activity-modal {
  .ant-modal {
    animation-duration: 0s !important;
  }
  @media (max-width: $md) {
    .ant-modal {
      top: 0 !important;
      width: 100vw !important;
      max-width: 100%;
      padding: 0;

      &-content {
        border-radius: 0 !important;
      }
    }
  }
}
