@import "../../assets/css/config.scss";

.search-container {
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: flex-end;

  .select-search-button {
    background-color: transparent;
    border: none;
    width: 30px;
    height: 30px !important;
    outline: none !important;
    cursor: pointer;
    padding: 3px !important;
  }
}

.popover-no-options {
  display: flex;
  width: 100%;
  justify-content: center;
}
