


.iwp-flag-settings-container {
  display: flex;
  gap: 26px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.contract-select {
  flex: 1;
  min-width: 300px;
  max-width: 50%;
}

.bulk-update-container {
  flex: 1;
  gap: 8px;
  align-items: center;
  
}

.bulk-update-text {
  min-width: 300px;
  font-size: 17px;
}

.bulk-update-select {
  width: 250px;
}
.space{
  margin-left: 5px;
}
.content-render{
  display: flex;
}






