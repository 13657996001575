@import "../../../assets/css/config.scss";

.logo_login {
  cursor: pointer;
}

.logo_dashboard {
  background-color: white;
  padding: 16px 24px 24px 24px;
  overflow: visible;
  width: 156px !important;
  min-height: 84px !important;
  cursor: pointer;
  border-bottom-left-radius: 30%;
  border-bottom-right-radius: 30%;
  margin-bottom: 16px;

  &:hover {
    opacity: 0.9;
  }

  @media screen and (min-width: $md) {
    margin-bottom: 0;
  }
}

.search,
.search:active,
.search:hover {
  border: 1px solid #acacac;
  background-color: #f7f7f7;
  color: #acacac;
  border-radius: 40px;
  font-size: 1.25vh;
  padding: 10px 15px 8px;
}

.avatarImage {
  background-color: white !important;
  color: var(--purple-500) !important;
}

.avatar-profile-photo {
  height: 40px !important;
  width: 40px !important;
  background: white !important;
  padding: 1px !important;

  img {
    border-radius: 50% !important;
  }
}

.search-desktop {
  display: none;

  input {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  }

  @media screen and (min-width: $md) {
    display: block;
  }
}

.header-layout {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;

  .project-title {
    font-weight: bold;
  }

  .header-text {
    font-size: 16px;
    color: white !important;    
  }
}

.header-row {
  justify-content: space-between;

  @media screen and (max-width: $md) {
    flex-direction: column !important;
    justify-content: center;
  }
}

.user-avatar {
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  color: white;

  &:hover {
    opacity: 0.8;
  }
}

.logout-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;

  &:hover {
    opacity: 0.8;
  }
}

.notification-icon {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.notifications {
  width: 340px !important;
  padding-right: 20px !important;

  @media screen and (max-width: $md) {
    width: 100vw !important;
  }

  .ant-popover-arrow {
    top: 16px !important;
  }

  .ant-popover-content {
    @media screen and (max-width: $md) {
      width: 100vw;
      padding: 0 12px;
    }

    .ant-popover-inner {
      padding: 0 !important;

      .ant-popover-title {
        padding: 8px 16px;
        text-align: center;
        margin: 0;
      }

      &-content {
        padding: 0 !important;

        .notifications-list {
          display: flex;
          flex-direction: column;

          .read {
            background-color: #fafafa;
            color: #888;
          }

          &-item:first-of-type {
            padding: 8px 12px 4px 12px !important;
          }

          &-item {
            padding: 4px 12px !important;
            font-size: 13px !important;
            border-bottom: 1px solid #ebebeb;
            background-color: #fff;
            cursor: pointer;

            &:hover {
              filter: brightness(0.98);
            }
          }

          &-action {
            display: flex;
            justify-content: center;
            padding: 8px 0;
            color: var(--purple-300);
            font-size: bold !important;
            background-color: #fff;
            cursor: pointer;
            border-radius: 0 0 8px 8px;

            &:hover {
              filter: brightness(0.98);
            }
          }
        }
      }
    }
  }
}
